import React from 'react';

function Posts(props) {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl mt-4 bg-white">
			<div className="text-center lg:max-w-5xl w-full p-4 mx-4 my-4 bg-white rounded-2xl">
				<div className="w-fit mx-auto py-6">
					<h2 className="lg:text-3xl text-2xl font-bold">Yeni Gönderlimiz</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<div className="flex flex-wrap lg:flex-nowrap gap-1">
					<div className="w-full lg:w-1/3 p-4">
						<div className="flex flex-col justify-center items-center gap-4">
							<div>
								<img
									className="rounded-lg ease-in duration-500"
									src="https://piritechnology.com/wp-content/uploads/2016/02/calculator-178127_640-340x240.jpg" alt="Post 1"/>
							</div>
							<h2 className="font-semibold text-xl">Post Başlık</h2>
							<span>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus architecto, at est ipsa laudantium molestiae, neque non perferendis quam qui ratione repellendus rerum sed sint. Devamı ...
							</span>
							<div className="mx-auto">
								<button className="bg-orange-400 rounded-xl px-4 py-2 lg:p-3 text-sm lg:text-base">Devamını Oku</button>
							</div>
						</div>
					</div>
					<div className="w-full lg:w-1/3 p-4">
						<div className="flex flex-col justify-center items-center gap-4">
							<div>
								<img
									className="rounded-lg ease-in duration-500"
									src="https://piritechnology.com/wp-content/uploads/2016/02/calculator-178127_640-340x240.jpg" alt="Post 1"/>
							</div>
							<h2 className="font-semibold text-xl">Post Başlık</h2>
							<span>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus architecto, at est ipsa laudantium molestiae, neque non perferendis quam qui ratione repellendus rerum sed sint. Devamı ...
							</span>
							<div className="mx-auto">
								<button className="bg-orange-400 rounded-xl px-4 py-2 lg:p-3 text-sm lg:text-base">Devamını Oku</button>
							</div>
						</div>
					</div>
					<div className="w-full lg:w-1/3 p-4">
						<div className="flex flex-col justify-center items-center gap-4">
							<div>
								<img
									className="rounded-lg ease-in duration-500"
									src="https://piritechnology.com/wp-content/uploads/2016/02/calculator-178127_640-340x240.jpg" alt="Post 1"/>
							</div>
							<h2 className="font-semibold text-xl">Post Başlık</h2>
							<span>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus architecto, at est ipsa laudantium molestiae, neque non perferendis quam qui ratione repellendus rerum sed sint. Devamı ...
							</span>
							<div className="mx-auto">
								<button className="bg-orange-400 rounded-xl px-4 py-2 lg:p-3 text-sm lg:text-base">Devamını Oku</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Posts;