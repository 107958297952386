import {Link} from "react-router-dom";
import {socials} from "../data";

function Socials() {
	return (
		<ul className="flex justify-center items-center gap-x-6 mx-4">
			{socials.map((item, index)=>{
				return(
					<li key={index}>
						<Link to={item.href} title={item.name}>{item.icon}</Link>
					</li>
				)
			})}
		</ul>
	);
}

export default Socials;