import React from 'react';
import img_about from "../assets/images/viacam_about.jpg"

function About() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl pt-16">
			<div className="flex flex-col text-center max-w-5xl p-4 mx-4 my-4 bg-white rounded-2xl gap-4">
				<div className="w-fit mx-auto py-4">
					<h2 className="lg:text-3xl text-2xl font-bold">Hakkımızda</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<div className="flex flex-col items-center gap-y-4">
					<div className="w-full">
						<img
							className="rounded-lg md:rounded w-full h-full object-cover"
							src={img_about}
							alt="random"
						/>
					</div>
					<div className="w-full">
						<p className="flex flex-col text-gray-600 gap-y-4">
							<span>
								Eğer bir güvenlik kamerası veya araç içi kamera kurulumu yapacak bir firma arıyorsanız, güvenilir ve deneyimli bir firma seçmeniz önemlidir.
							</span>
							<span>
								Bu alanda hizmet veren bir firma olarak, sizin ihtiyaçlarınıza uygun bir çözüm sunarak, ev veya işyerinizin güvenliğini sağlamaya yardımcı olabiliriz. Firmamız, kaliteli ve güvenilir kameralarla birlikte, uzman ekibimiz aracılığıyla profesyonel montaj ve kurulum hizmeti sunmaktadır.
							</span>
						</p>
					</div>
				</div>
				<div className="flex flex-col items-center gap-y-4">
					<div className="md:pl-4 w-full">
						<p className="flex flex-col text-gray-600 gap-y-4">
							<span>
								Bizimle çalışmanın avantajları arasında, müşterilerimize en uygun çözümleri sunarak, müşteri memnuniyeti odaklı çalışmamız ve satış sonrası desteğimiz yer alır. Ayrıca, müşterilerimizin güvenliği bizim için her zaman önceliklidir.
							</span>
							<span>
								Güvenlik kamerası veya araç içi kamera kurulumu yapmak isteyen müşterilerimiz, firmamızla iletişime geçerek, ihtiyaçlarına uygun çözümler hakkında daha detaylı bilgi alabilirler.
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;