import {Link} from "react-router-dom";
import {navigation} from "../data"

function Nav() {
	return (
		<nav>
			<ul className="flex gap-x-14 items-center">
				{navigation.map((item,index)=>{
					if (index === 2) {
					return (
						<li className="group relative" tabIndex="-1" key={index}>
							<button className="rounded-md px-3 py-1 hover:bg-black/50">Ürün ve Hizmetler</button>
							<div
								className="invisible absolute top-full w-48 transform opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100">
								<ul className="mt-1 rounded-md border border-black bg-black/50 py-1 shadow backdrop-blur-sm">
									<li>
										<a href="/arac-ici-kamera-sistemleri" target="_self"
											className="flex items-center justify-between px-3 py-0.5 text-sm hover:bg-black/5"><span>Araç Takip Sistemleri</span><span
											className="text-xs opacity-50">
								</span></a>
									</li>
								</ul>
							</div>
						</li>
					)}
					return(
						<li className="group relative" key={index}>
							<Link to={item.href}
							      className="rounded-md px-3 py-1 hover:bg-black/5 text-white"
							>
								{item.name}
							</Link>
							{/*<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 delay-75 h-0.5 bg-gradient-to-r from-white to-rose-800"></span>*/}
						</li>
					)
				})}
			</ul>
		</nav>
	);
}

export default Nav;