import Hero from "../components/home/Hero";
import About from "../components/home/About";
import Partners from "../components/home/Partners";
import PAS from "../components/home/PAS";
import Posts from "../components/home/Posts";
import Contact from "../components/home/Contact";

function Home() {

	return (
		<>
			<Hero/>
			<div className="min-h-screen flex flex-col space-y-4">
				<About />
				<Partners />
				<PAS />
			</div>
			<Posts />
			<Contact />
		</>
	);
}

export default Home;