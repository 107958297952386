function Partners() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl bg-white">
			<div className="text-center max-w-5xl p-4 mx-4 my-4 bg-white rounded-2xl">
				<div className="w-fit mx-auto">
					<h2 className="lg:text-3xl text-2xl font-bold">İş Ortaklarımız</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<p className="flex flex-col gap-y-6 lg:text-xl text-lg py-4">
		      <span>
						Birlikte çalıştığımız, ürünlerini ve hizmetlerini sattığımız firmalar:<br/>
			      Garmin, Nextbase, BlackVue, Thinkware, Viofo, Papago, Transcend, Vantrue, Rexing, Street Guardian, Apeman, Yi Technology
					</span>
				</p>
				<div className="mx-auto">
					<button className="bg-orange-400 rounded-2xl p-2 lg:p-3 text-sm lg:text-base">Hepsini Görüntüleyin</button>
				</div>
			</div>
		</div>
	);
}

export default Partners;