import {useState, useEffect, useCallback} from "react"
import { ChevronLeft, ChevronRight } from "react-feather"

function Carousel({
	                  children: slides, data,
	                  autoSlide = false,
	                  autoSlideInterval = 3000,
                  }) {
	const [curr, setCurr] = useState(0)

	const prev = () =>
		setCurr( (curr) => (curr === 0 ? slides.length - 1 : curr - 1) )
	const next = useCallback(() =>
		setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1)), [slides.length]
	)


	useEffect(() => {
		if (!autoSlide) return
		const slideInterval = setInterval(next, autoSlideInterval)
		return () => clearInterval(slideInterval)
	}, [autoSlide, autoSlideInterval, curr, next])

	return (
		<div className="overflow-hidden relative">
			<div
				className="flex transition-transform ease-out duration-500"
				style={{ transform: `translateX(-${curr * 100}%)` }}
			>
				{slides}
			</div>
			<div className="absolute inset-0 flex items-center justify-between p-4">
				<button
					onClick={prev}
					className="opacity-60 rounded-full shadow bg-white/80 cursor-pointer text-gray-800 hover:bg-white z-10"
				>
					<ChevronLeft size={40} />
				</button>
				<button
					onClick={next}
					className="opacity-60 rounded-full shadow bg-white/80 cursor-pointer text-gray-800 hover:bg-white z-10"
				>
					<ChevronRight size={40} />
				</button>
			</div>

			<div className="absolute top-[11%] lg:top-[20%] bottom-[10%] right-0 left-0">
				<div className="flex flex-col items-center justify-center text-center text-black outline text-3xl gap-y-6 p-6 rounded-2xl max-w-xs h-full lg:max-w-screen-lg mx-auto">
					<div className="flex items-center justify-center">
						{data[curr].icon}
					</div>
					<h1 className="text-3xl">{data[curr].title}</h1>
					 <p className="text-xl">{data[curr].desc}</p>
				</div>
			</div>

			<div className="hidden lg:block absolute bottom-4 right-0 left-0">
				<div className="flex items-center justify-center gap-2">
					{slides.map((_, i) => (
						<div
							key={i}
							className={`
              transition-all w-3 h-3 bg-white rounded-full
              ${curr === i ? "p-2" : "bg-opacity-50"}
            `}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default Carousel;