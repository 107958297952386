import {Route, Routes} from "react-router-dom";

import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PAS from "./pages/PAS";
import Acam from "./pages/Acam";

function App() {
  return (
    <>
      <Header />
      <div className="">
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/hakkimizda" element={<About/>}/>
          <Route exact path="/iletisim" element={<Contact/>}/>
          <Route exact path="/urun-ve-hizmetlerimiz" element={<PAS/>}/>
          <Route exact path="/arac-ici-kamera-sistemleri" element={<Acam/>}/>
        </Routes>
      </div>
      <Footer/>
    </>
  );
}

export default App;
