import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {navigation} from "../data";
import Socials from "./Socials";

function NavMobile({setNavMobil}) {
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => {setIsOpen(!isOpen); setNavMobil(false)};

	return (
		<nav className="w-full h-full flex flex-col justify-evenly overflow-hidden items-center">
			<ul className="flex flex-col justify-center items-center gap-y-6 py-4 mb-8">
				{navigation.map((item, index)=>{
					if (index === 2) {
						return (
							<li key={index} className="">
								<div className={``}>
									<button
										onClick={()=> setIsOpen(!isOpen)}
										className="px-4 py-2 text-2xl "
									>
										Ürünler Ve Hizmetler
									</button>
									{isOpen && (
										<div
											className={`flex flex-col left-0 top-0 items-center justify-center shadow-lg transition-max-height duration-300 
											${ isOpen ? 'max-h-full' : 'max-h-0'}`}>
											<ul className="py-2">
												<li className="px-4 py-2">
													<Link
														onClick={()=>handleToggle()}
														to="/arac-ici-kamera-sistemleri"
														className="text-md">
														Arac İçi Kamera Sistemleri
													</Link>
												</li>
												<li className="px-4 py-2">
													<Link
														onClick={()=>handleToggle()}
														to="/urun-ve-hizmetlerimiz"
														className="text-md">
														Arac Takip Sistemleri
													</Link>
												</li>
											</ul>
										</div>
									)}
								</div>
							</li>
						)
					}
					return(
						<li key={index}>
							<Link
								onClick={()=>setNavMobil(false)}
								to={item.href}
								className="text-2xl">
								{item.name}
							</Link>
						</li>
					)
				})}
			</ul>
			<div className="text-2xl">
				<Socials/>
			</div>
		</nav>
	);
}

export default NavMobile;