import React from 'react';
import {contact} from "../data";
import {BsClockHistory} from "react-icons/bs";

function Contact() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl pt-16">
			<div className="text-center w-screen lg:max-w-5xl  p-4 m-4 bg-white rounded-2xl">
				<div className="w-fit mx-auto py-4">
					<h2 className="lg:text-3xl text-2xl font-bold">İletişim</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<div className="flex flex-col md:flex-row justify-center items-center text-left">
					<div className="space-y-8 mb-12">
						{contact.map((item, index)=>{
							const {icon, title, subtitle, description, link} = item
							return (
								<div className="flex flex-row gap-x-4" key={index}>
									<div
										className="text-black rounded-sm h-14 flex items-start justify-center mt-2 mb-2 lg:mb-0 text-2xl">
										{icon}
									</div>
									<div>
										<h3 className="text-2xl mb-1">{title}</h3>
										<p className="mb-1">{subtitle}</p>
										<a href={link} target="_blank" rel="noopener noreferrer"><p className="text-orange-400 font-normal">{description}</p></a>
									</div>
								</div>
							)
						})}
						<div className="flex flex-row gap-x-4">
							<div
								className="h-14 flex items-start justify-center mt-2 mb-2 lg:mb-0 text-2xl">
								<BsClockHistory/>
							</div>
							<table className="table border-none">
								<h4 className="text-2xl pb-4">Çalışma Saatlerimiz</h4>
								<tbody>
								<tr>
									<td className="border-0">Pazartesi:</td>
									<td className="border-0">09:00 - 18:00</td>
								</tr>
								<tr>
									<td className="border-0">Salı:</td>
									<td className="border-0">09:00 - 18:00</td>
								</tr>
								<tr>
									<td className="border-0">Çarşamba:</td>
									<td className="border-0">09:00 - 18:00</td>
								</tr>
								<tr>
									<td className="border-0">Perşembe:</td>
									<td className="border-0">09:00 - 18:00</td>
								</tr>
								<tr>
									<td className="border-0">Cuma:</td>
									<td className="border-0">09:00 - 18:00</td>
								</tr>
								<tr>
									<td className="border-0">Cumartesi:</td>
									<td className="border-0">10:00 - 14:00</td>
								</tr>
								<tr>
									<td className="border-0">Pazar:</td>
									<td className="border-0">Kapalı</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="space-y-8 h-[700px] w-full lg:w-8/12">
						<iframe className="rounded-lg" title="Via Bilişim Konum" width="100%" height="100%"
						        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d482.4908244933677!2d27.24745708799824!3d38.46145684362845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b97c97dd1be19d%3A0x34ec39538ce32102!2zVmlhIEJpbGnFn2ltIFRla25vbG9qaWsgw5xyw7xubGVyIEEuxZ4u!5e0!3m2!1str!2str!4v1681303631597!5m2!1str!2str"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contact;