import React from 'react';

function Pas() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl bg-white mt-4">
			{/*Üst divdeki bg-white etiketini silerek kutu görünümü elde edebilirsin.*/}
			<div className="text-center max-w-5xl p-4 mx-4 my-4 bg-white rounded-2xl">
				<div className="w-fit mx-auto">
					<h2 className="lg:text-3xl text-2xl font-bold">Ürünler ve Hizmetler</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<div className="flex flex-col gap-y-6 lg:text-xl text-lg py-4">
						<p>Araç içi güvenlik kameraları, sürüş esnasında güvende hissetmenizi sağlayan cihazlardır. Kazalar veya hırsızlık olayları gibi beklenmedik durumlarda, aracınızın içinde olup bitenleri kaydederler. Böylece aracınıza yönelik olası tehlikeleri önceden tespit edebilir ve güvenliğinizi artırabilirsiniz.</p>

						<p>Araç içi kameralar, özellikle aracınızı kiralamak veya paylaşmak gibi durumlarda da oldukça faydalıdır. Aracınızın kullanıcılarının davranışlarını izleyebilir ve güvenliğinizi sağlayabilirsiniz. Ayrıca, kazalar veya çarpışmalar sırasında oluşan hasarın tespit edilmesine de yardımcı olurlar.</p>

						<p>Araç içi güvenlik kameralarının avantajlarından biri de, yüksek çözünürlüklü görüntüler sağlamalarıdır. Bu sayede, kaydedilen görüntülerde ayrıntıları net bir şekilde görebilirsiniz. Ayrıca, en son teknoloji ile donatılmış kameralarımız, kullanımı kolay ve kurulumu hızlıdır.</p>

						<p>Sonuç olarak, araç içi güvenlik kamerası, sürüş sırasında kendinizi güvende hissetmeniz için ihtiyaç duyacağınız bir cihazdır. Kazalar veya hırsızlık olayları gibi beklenmedik durumlara karşı hazırlıklı olmak istiyorsanız, araç içi güvenlik kamerası yaptırabilirsiniz. Böylece, aracınızın güvenliğini artırabilir ve sürüş keyfinizi maksimize edebilirsiniz.</p>
				</div>
			</div>
		</div>
	);
}

export default Pas;