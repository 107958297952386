import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Nav from "./Nav";
import NavMobile from "./NavMobile";
// import {TiThMenuOutline} from "react-icons/ti";
import Socials from "./Socials";

function Header() {
	const [isActive, setIsActive] = useState(false)
	const [navMobil, setNavMobil] = useState(false)

	useEffect(()=>{
		window.addEventListener("scroll", ()=>{
			return window.scrollY > 20 ? setIsActive(true) : setIsActive(false)
		})
	})

	return (
		<header
			className={`${isActive ? "bg-black h-12 shadow-lg": "bg-black/50 h-16"} block items-center fixed w-full mx-auto text-white z-20 transition-all duration-300`}>
			<div className="container mx-auto h-full max-w-6xl flex items-center justify-between">
				<div className="px-4 cursor-pointer">
					<Link to="/" className="shadow-2xl drop-shadow-2xl border border-white p-2 rounded-full">
						ViaTech
					</Link>
				</div>
				<div className="hidden lg:block">
					<Nav />
				</div>
				<div className="hidden lg:block text-xl">
					<Socials />
				</div>
				<div onClick={()=>setNavMobil(!navMobil)} className="p-2 space-y-2 lg:hidden right-[3%] rounded-md cursor-pointer w-10 h-10 relative text-white">
					<div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<span className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${navMobil ? 'rotate-45' :'-translate-y-1.5'}`}></span>
						<span className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${navMobil ? 'opacity-0' :''}`}></span>
						<span className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${navMobil ? '-rotate-45' :'translate-y-1.5'}`}></span>
					</div>
				</div>
				<div className={`${navMobil ? 'max-h-full': 'max-h-0' } ${isActive ? "top-[40px]": "top-[60px]"} bg-black/90 fixed w-full h-full left-0 -z-10 transition-all duration-300`}>
					<NavMobile setNavMobil={setNavMobil} />
				</div>
			</div>
		</header>
	);
}

export default Header;