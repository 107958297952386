import React from 'react';
import img_about from "../assets/images/viacam_about.jpg";
import img_pas1 from "../assets/images/viacam_pas1.jpg"
import img_pas2 from "../assets/images/viacam_pas2.jpg"
import {pas} from "../data";

function Pas() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl pt-16">
			<div className="flex flex-col text-center max-w-5xl p-4 mx-4 my-4 bg-white rounded-2xl gap-4">
				<div className="w-fit mx-auto py-4">
					<h2 className="lg:text-3xl text-2xl font-bold">Ürün ve Hizmetlerimiz</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				{pas.map((item, index)=>{
					return (
						<div className="flex flex-col md:flex-row items-center gap-y-4" key={index}>
							<div className={`${index % 2 === 0 ? 'md:order-2' : 'md:order-1'} w-full md:w-1/2`}>
								<img
									className="rounded-lg md:rounded w-full h-full object-cover"
									src={img_pas1}
									alt="random"
								/>
							</div>
							<div className={`${index % 2 === 0 ? 'md:order-1' : 'md:order-2'} md:pr-4 w-full md:w-1/2`}>
								<h2 className="lg:text-2xl text-xl font-bold py-2">{item.title}</h2>
								<p className="flex flex-col text-gray-600">
									Bu alanda hizmet veren bir firma olarak, sizin ihtiyaçlarınıza uygun bir çözüm sunarak, ev veya işyerinizin güvenliğini sağlamaya yardımcı olabiliriz. Firmamız, kaliteli ve güvenilir kameralarla birlikte, uzman ekibimiz aracılığıyla profesyonel montaj ve kurulum hizmeti sunmaktadır.
								</p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Pas;