import React from 'react';
import img_pas1 from "../assets/images/viacam_pas1.jpg";

function Acam() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl pt-16">
			<div className="flex flex-col text-center max-w-5xl p-4 mx-4 my-4 bg-white rounded-2xl gap-4 items-center">
				<div className="w-fit mx-auto py-4">
					<h2 className="lg:text-3xl text-2xl font-bold">Araç İçi Kamera Sistemleri</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<div className="flex flex-col md:flex-row items-center gap-y-4">
					<div className={`md:order-1 w-full md:w-1/2`}>
						<img
							className="rounded-lg md:rounded w-full h-full object-cover"
							src={img_pas1}
							alt="random"
						/>
					</div>
					<div className={`md:order-2 md:pr-4 w-full md:w-1/2`}>
						<h2 className="lg:text-2xl text-xl font-bold py-2">Mini-Viacam Araç Kamerası</h2>
						<p className="flex flex-col text-gray-600 rounded-lg px-2">
							Bu alanda hizmet veren bir firma olarak, sizin ihtiyaçlarınıza uygun bir çözüm sunarak, ev veya işyerinizin güvenliğini sağlamaya yardımcı olabiliriz. Firmamız, kaliteli ve güvenilir kameralarla birlikte, uzman ekibimiz aracılığıyla profesyonel montaj ve kurulum hizmeti sunmaktadır.
						</p>
					</div>
				</div>

				<div className="flex flex-col w-full lg:w-1/2">
					<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
							<div className="overflow-hidden">
								<table className="min-w-full text-left text-sm font-light bg-gray-200 rounded-t-lg">
									<thead className="border-none font-medium dark:border-neutral-500">
									<tr>
										<th className="py-4 text-center" colSpan="3">Model: Mini-Viacam Teknik Özellikler</th>
									</tr>
									</thead>
									<tbody>
									<tr className="border-b dark:border-neutral-500">
										<td className="whitespace-nowrap px-6 py-4">Boyut</td>
										<td className="whitespace-nowrap px-6 py-4">3x3x3 cm^3</td>
									</tr>
									<tr className="border-b dark:border-neutral-500">
										<td className="whitespace-nowrap px-6 py-4">İşlemci</td>
										<td className="whitespace-nowrap px-6 py-4">A15</td>
									</tr>
									<tr className="border-b dark:border-neutral-500">
										<td className="whitespace-nowrap px-6 py-4">Larry</td>
										<td className="whitespace-nowrap px-6 py-4">Wild</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="h-96 w-full">
					<iframe src="https://www.youtube.com/embed/oWxiqGhsJsw" frameBorder="0" className="rounded-lg h-full w-full"
					        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					        allowFullScreen title="Cam Video"></iframe>
				</div>

			</div>
		</div>
	);
}

export default Acam;