function About() {
	return (
		<div className="flex items-center justify-center drop-shadow-2xl bg-white mt-4">
			<div className="text-center max-w-5xl p-4 mx-4 my-4 bg-white rounded-2xl">
				<div className="w-fit mx-auto">
					<h2 className="lg:text-3xl text-2xl font-bold">Hakkımızda</h2>
					<span className="block max-w-full h-0.5 bg-orange-400"></span>
				</div>
				<p className="flex flex-col gap-y-6 lg:text-xl text-lg py-4">
      <span>
        Taksilerin güvenliği, müşterilerin güvenliği kadar önemlidir. Yolculuk sırasında yaşanabilecek herhangi bir olumsuz durumda, güvenlik kameraları önemli bir rol oynamaktadır. Ancak, güvenlik kameraları sadece görüntü kaydı yapmakla sınırlıdır ve taksilerde yaşanan pek çok olumsuz durumda yeterli olamayabilmektedir. Bu nedenle, yapay zeka teknolojisiyle donatılmış ve ses algılama özelliği olan güvenlik kameraları geliştirilmiştir.
      </span>
				</p>
			</div>
		</div>
	);
}

export default About;