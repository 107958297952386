// import img1 from "./assets/images/photo-1477346611705-65d1883cee1e.jpeg"
import img2 from "./assets/images/image2.jpg"
import img3 from "./assets/images/cam.jpg"

import {
	IoLogoInstagram,
	IoLogoTwitter,
	IoLogoFacebook,
} from 'react-icons/io';

import {
	FiMail,
	FiMapPin,
	FiPhone
} from 'react-icons/fi';

import { RiEye2Line } from 'react-icons/ri'
import { MdSecurity } from 'react-icons/md'
import { GiMicrochip } from 'react-icons/gi'

export const navigation = [
	{
		name: "Anasayfa",
		href: "/"
	},
	{
		name: "Hakkımızda",
		href: "/hakkimizda"
	},
	{
		name: "Ürün ve Hizmetler",
		href: "/urun-ve-hizmetler"
	},
	// {
	// 	name: "Araç İçi Kamera Sistemleri",
	// 	href: "/arac-ici-kamera-sistemleri"
	// },
	// {
	// 	name: "Çözüm Ortaklarımız",
	// 	href: "cozum-ortaklarimiz"
	// },
	{
		name: "İletişim",
		href: "/iletisim"
	},
]

export const images = [
	{
		img: "https://www.gannett-cdn.com/presto/2019/03/06/USAT/dbee093f-65b9-4cd1-866e-524761d66986-Large-35791-HyundaiMotorSharesFirstGlimpseofAll-NewSonata.jpg?width=1320&height=776&fit=crop&format=pjpg&auto=webp",
		title: "IPCAM İle Gözünüz Aracınızın İçinde Olsun",
		desc: "İstediğiniz yerde istediğiniz zaman sadece bir internet bağlantısı sayesinde aracınızın güvenliğini kontrol edin",
		icon: <RiEye2Line size={50}/>
	},
	{
		img: img2,
		title: "Araç İçi Güvenlik Kameraları İle Sürüşünüzü Güvence Altına Alın",
		desc: "Araç içi güvenlik kameraları, sürüş esnasında size ve aracınıza ekstra bir güvence sağlar. Beklenmedik durumlar karşısında aracınızın içinde olanları kaydeder ve böylece olası sorunları önceden tespit etmenize olanak tanır.",
		icon: <MdSecurity size={50}/>
	},
	{
		img: img3,
		title: "En Son Teknoloji İle Donatılmış Araç İçi Kameralar",
		desc: "Ayrıca, yüksek çözünürlüklü görüntüler sağlamaları, hızlı kurulumu ve uzun ömürlü olmaları sayesinde müşterilerin memnuniyetini artıracak özellikleri de vurgulayabilirsiniz. Bu kameralar, güvenliği artırmanın yanı sıra araç sahiplerine konforlu ve stressiz bir sürüş deneyimi sunar.",
		icon: <GiMicrochip size={50}/>
	},
]

export const socials = [
	{
		name: "@viamedya Instagram",
		icon: <IoLogoInstagram className="hover:bg-[#d62976] text-white rounded-md" />,
		href: "https://instagram.com/viamedya"
	},
	{
		name: "@viamedya Twitter",
		icon: <IoLogoTwitter className="hover:bg-white text-[#1DA1F2] rounded-md" />,
		href: "https://twitter.com/viamedya"
	},
	{
		name: "@viamedya Facebook",
		icon: <IoLogoFacebook className="hover:bg-[#17A9FD] text-white rounded-md" />,
		href: "https://facebook.com/viamedya"
	},
]

export const contact = [
	{
		icon: <FiPhone />,
		title: 'Bizi arayın',
		subtitle: 'Telefon numaramız',
		description: '0533 811 08 11',
		link: 'tel:+905338110811'
	},
	{
		icon: <FiMail />,
		title: 'Bizimle iletişime geçin',
		subtitle: 'Size nasıl yardımcı olabiliriz?',
		description: 'info@viamedya.com',
		link: 'mailto:info@viamedya.com'
	},
	{
		icon: <FiMapPin />,
		title: 'Adresimiz',
		subtitle: 'Evka 3, 119/30. Sk. No: 8A, 35050 Bornova / İzmir',
		description: 'Nasıl giderim?',
		link: 'https://www.google.com/maps/dir//38.4238889,27.1399444/@38.423891,27.139949,16z?hl=tr-TR'
	},
];

export const pas =[
	{
		title: "Araç İçi Kameralar",
		desc: "Araçlarınızın içindeki her şeyin kontrol altında olduğundan emin olmak ister misiniz? Araç içi kameralarımız, sürücülerin, araçlarda olanların güvenliği hakkında bilgi sahibi olmalarını sağlar. Teknolojimiz, görüntü kalitesi yüksek, kaydedilen videoları bulanık olmayan, net bir şekilde izlenebilir hale getirir. Siz de araçlarınızda bizimle çalışarak, güvenliğinizi en üst düzeyde tutabilirsiniz."
	},
	{
		title: "Sürücü Davranışı Takibi",
		desc: "Sürücülerinizin davranışlarını takip ederek, araç kazalarını önlemek mümkün. Şirketimiz, sürücü davranışı takibi ile sürücülerinizin hareketlerini izleyerek, herhangi bir tehlikeli davranış veya uygunsuzluk tespit ettiğinde, sizi uyarır. Böylece sürücülerinizin güvenliği ve araçlarınızın bakımı için gereken önlemleri alabilirsiniz."
	},
	{
		title: "Araçtan Takip ve Yönetim",
		desc: "Araçlarınızın nerede olduğunu biliyor musunuz? Araç takip teknolojisi, şirketlerin araçlarının konumlarını ve hareketlerini gerçek zamanlı olarak takip etmesini sağlar. Bu sayede, sürücülerin hangi yolu izlediğini, hangi hızla gittiklerini, araçların bakım ve yakıt ihtiyaçlarını kontrol edebilirsiniz. Böylece, araçlarınızı daha verimli bir şekilde yönetebilirsiniz."
	},
	{
		title: "İzinsiz Giriş Tespiti",
		desc: "Şirketlerin sahip olduğu araçların izinsiz kullanımı, büyük bir sorun olabilir. Biz, izinsiz giriş tespiti teknolojisi ile araçlarınızın kim tarafından kullanıldığını kontrol ederiz. Bu sayede, araçların güvenliğini sağlamak ve olası hırsızlık durumlarını önlemek mümkün hale gelir."
	},
	{
		title: "Araç İçi Güvenlik Kamerası Montajı",
		desc: "Araç içi güvenlik kameralarını en etkili hale getirmek için doğru bir şekilde montaj edilmeleri gerekir. Firmamız, deneyimli teknisyenleri tarafından araçlara profesyonel montaj hizmeti sunar. Montaj işlemi, aracın elektrik sistemine bağlanmasını ve kameraların doğru pozisyonda yerleştirilmesini içerir. Bu sayede, araç içi güvenlik kameralarınız en yüksek performansla çalışır ve size güvenliği garanti eder."
	},
	{
		title: "Güvenlik Kamerası Yazılımı",
		desc: "Firmamız, en son teknolojileri kullanarak güvenlik kamerası yazılımı geliştirir. Bu yazılımlar, güvenlik kameralarından gelen görüntüleri analiz eder ve kaydeder. Gelişmiş özellikleri sayesinde, hareket algılama, yüz tanıma ve plaka tanıma gibi işlevleri gerçekleştirebilir. Böylece, güvenlik kameralarınızın işlevselliği artar ve size daha fazla güvenlik sağlar.\n"
	},
	{
		title: "Güvenlik Kamerası Bakımı ve Onarımı",
		desc: "Güvenlik kameralarının düzenli bakımı ve onarımı, cihazların ömrünü uzatır ve işlevselliğini korur. Firmamız, uzman teknisyenleri tarafından kameraların bakım ve onarım işlemlerini gerçekleştirir. Böylece, güvenlik kameralarınızın sürekli olarak en yüksek performansla çalışmasını sağlar ve sizi herhangi bir güvenlik açığından korur."
	},

]