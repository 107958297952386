import React from 'react';

function Contact() {
	return (
		<section className="bg-white">
			<div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
				<h2 className="mb-4 text-3xl tracking-tight font-extrabold text-center text-gray-900">
					Bize Ulaşın
				</h2>
				<p className="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">
					Aklınıza takılan bir şey var ya da teklif almak istiyorsanız bize yazmanız yeterli
				</p>
				<form action="#" className="space-y-8">
					<div>
						<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
							Email adresiniz:
						</label>
						<input type="email" id="email"
						       className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
						       placeholder="mail@adresiniz.com" />
					</div>
					<div>
						<label htmlFor="subject"
						       className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Konu:</label>
						<input type="text" id="subject"
						       className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
						       placeholder="Konuşmak istediğiniz konu başlığı" />
					</div>
					<div className="sm:col-span-2">
						<label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your
							message</label>
						<textarea id="message" rows="6"
						          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
						          placeholder="Mesajınızı bu alana yazınız"></textarea>
					</div>
					<div className="flex justify-end">
						<button type="submit"
						        className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-primary-300">
							Mesajı Gönder
						</button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default Contact;