import Carousel from "./Carousel";
import {images} from "../../data";


function Hero() {
	return (
		<>
			<Carousel data={images} autoSlide={true}>
				{images.map((item,index)=>{
					return(
						<img className="object-cover min-w-full h-screen opacity-40" src={item.img} key={index} alt={""}>
						</img>
					)
				})}
			</Carousel>
		</>
	);
}

export default Hero;